/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *


 */

//@import "bootstrap-sprockets";
//@import "bootstrap";
//@import "demos/default/plugins";
//@import "demos/default/components";

@import "normalize";
@import "webflow";
@import "northeasternenv.webflow";
@import "animations";

// Global styles
@import "fa-svg-with-js";
@import "animate.min";
@import "bootstrap.min";

// Base plugins
@import "cubeportfolio.min";
@import 'owl.carousel.min';
@import 'owl.theme.default.min';
@import "jquery.fancybox";
@import "slider";

// Theme styles
@import "plugins";
@import "components";
@import "default";
@import "custom";

@import "overwrites";
@import "interior-vars";
@import "interior-pages";




#login-container{
    margin: 0 auto;    
}

#login_form{
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 40px auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .06);
    
    .actions {
        margin-bottom: 10px;
    }

    input{
        font-family: "Roboto", sans-serif;
        outline: 0;
        background: #f2f2f2;
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
    }

    button{
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        outline: 0;
        background: #4CAF50;
        width: 100%;
        border: 0;
        padding: 15px;
        color: #FFFFFF;
        font-size: 14px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;


    }

    button:hover,.form button:active,.form button:focus {
        background: #43A047;
    } 
}
