
section[class^="interior-section"] {
	
	
	article {
		display: block;
		margin: 0 auto;
		max-width: $article-width;
		font-size: 0;
		padding: 20px 0;
		@media(max-width: $article-width) {
			padding: 20px;
		}
		
	}
	
	header {
		display: block;
		position: relative;
		padding-top: 40px;
		
		$i-width: 82px; 
		.svg-inline--fa {
			display: block;
			position: absolute;
			color: $brand-green;
			font-size: 160px;
			width: 160px;
			border: 18px solid $brand-green;
			border-radius: 100%;
			padding: 20px;
			opacity: 0.1;
			left: -82px;
			top: 0;

		}
	}
	
	
	h1 {
		font-family: Roboto Condensed;
		font-style: normal;
		font-weight: bold;
		line-height: normal;
		font-size: 48px;
		text-transform: uppercase;
		color: $brand-green;
		margin: 0;
	}
	
	.bed-bug__header-wrapper {
		width: 100%;
		margin-top: 20px;
		text-align: center;

	}
	
	h4 {
		display: inline-block;
		position: relative;
		font-family: Roboto Condensed;
		font-style: normal;
		font-weight: bold;
		line-height: normal;
		font-size: 24px;
		text-transform: uppercase;
		color: $brand-green;
		padding: 15px 40px;
		border: 4px solid $brand-green;
	}
	
	
	p {
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		font-size: 16px;
		color: $text-gray;
		margin: 10px 0;
	}
	
	strong {
		display: block;
		font-size: 18px;
		font-weight: 700;
		min-height: 50px;
	}
	
	a {
		color: $brand-green;
	}
	
	em {
		font-style: normal;
		color: darken($brand-green, 5%);
		font-weight: 700;
	}
	
	ul {
		padding-left: 2rem;
		text-align: left;
	}
	
	li {
		@extend p;
		
		font-size: 18px;
		line-height: 26px;
		font-weight: 500;
		color: darken($brand-green, 5%);
		list-style: square;
		
	}
	
	
	figure {
		display: inline-block;
		vertical-align: top;
		position: relative;
		width: calc(50% - 30px);
		
		margin: 0 40px 0 0;
		
		z-index: 0;
		
		&:nth-of-type(even) {
			margin-right: 20px;
		}
		
		@media(max-width: $break1) {
			width: 100%;
			margin: 20px 0 0;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
		}
		
		h4 {
			font-family: Roboto Condensed;
			font-style: normal;
			font-weight: bold;
			line-height: normal;
			font-size: 24px;
			text-transform: uppercase;
			color: #404449;
			margin: 0;
			position: absolute;
			background-color: rgba(#fff, 0.6);
			top: 20px;
			left: 20px;
			right: 20px;
			padding: 10px;
			border: none;
						
			z-index: 2;
			
		}
		
		.img-wrapper {
			display: block;
			position: relative;
			overflow: hidden;

			width: 100%;
			height: 200px;
			
			z-index: 1;
			
			@media(max-width: $break1) {
				height: 120px;
			}
		}
		
		
		img {
			display: block;
			position: absolute;
			object-fit: cover; // Image behavior for newer browsers.

			/* Image center fallback for older browsers.
			Position the image in the middle of its container.*/
			top: -9999px;
			right: -9999px;
			bottom: -9999px;
			left: -9999px;
			margin: auto;

			/* The following values determine the exact image behaviour. */
			/* You can simulate background-size: cover/contain/etc.
			by changing between min/max/standard width/height values.
			These values simulate background-size: cover */
			width: 100%;
			min-height: 100%;
			
			z-index: 2;
		}
		
		figcaption {
			display: block;
			position: relative;
			@extend p;
			
			padding: 20px;
			background-color: #fff;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
			text-align: left;
			
			transform: translate(20px, -78px);
			
			z-index: 3;
			
			@media(max-width: $break1) {
				transform: none !important;
				box-shadow: none;
				margin: 0;
			}
		}
		
	}

.intro__text {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

p[class*="--50"] {
	@extend .intro__text;
	width: calc(50% - 20px);
	
	&:nth-of-type(even) {
		margin-left: 20px;
	}
	
	@media(max-width: $break1) {
		width: 100%;
		margin-left: 0 !important;
		margin-bottom: 0 !important;
	}
}

div[class*="-row"] {
	display: block;
	width: 100%;
	font-size: 0;
	> * {
		font-size: 1em;
	}
}

div[class*="--33"] {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	width: calc((100% - 40px) / 3);
	
	&:nth-child(1) {
		margin-right: 20px;
	}
	
	&:nth-child(3) {
		margin-left: 20px;
	}
	
	span[class*="number--circled"] {
		display: block;
		font-size: 96px;
		line-height: 90px;
		font-weight: 700;
		color: $brand-green;
		border: 12px solid $brand-green;
		border-radius: 100%;
		padding: 10px;
		width: 140px;
		height: 140px;
		margin: 20px auto 30px;
	}
	
	$i-width: 64px;
	.svg-inline--fa {
		display: block;
		color: $brand-green;
		font-size: 100px;
		width: 100px;
		padding: 20px;
		margin: 0 auto;
	}
	
	@media(max-width: $break1) {
		width: 100%;
		margin: 20px 0 0 !important;
	}
	
	
}

div[class*="--50"] {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	width: calc((100% - 40px) / 2);
	
	&:nth-child(1) {
		margin-right: 20px;
	}
	
	&:nth-child(2) {
		margin-left: 20px;
	}
	
	span[class*="number--circled"] {
		display: block;
		font-size: 96px;
		line-height: 90px;
		font-weight: 700;
		color: $brand-green;
		border: 12px solid $brand-green;
		border-radius: 100%;
		padding: 10px;
		width: 96px;
		height: 96px;
		margin: 20px auto 30px;
	}
	
	$i-width: 64px;
	.svg-inline--fa {
		display: block;
		color: $brand-green;
		font-size: 100px;
		width: 100px;
		padding: 20px;
		margin: 0 auto;
	}
	
	@media(max-width: $break1) {
		width: 100%;
		margin: 20px 0 0 !important;
	}
	
	
}

.industry-solutions__header {
	margin-bottom: 25px;
	
	@media(max-width: $break1) {
		margin-bottom: 0;
	}
}


.other-services__card {
	display: inline-block;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	
	h4 {
		top: auto !important;
		bottom: 20px;
	}
	
	.img-wrapper {
		@media(max-width: $break1) {
			height: 320px !important;
		}
	}
}



	
}

.interior-section--gray {
	background: $interior-section-gray;
}

.hero-image {
	display: block;
	position: relative;
	height: 200px;
	width: 100%;
	padding: 0;
	margin: 0;
	opacity: 0.5;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}


.other-services__card {
	display: flex;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	
	h4 {
		top: auto !important;
		bottom: 20px;
	}
	
	.img-wrapper {
		@media(max-width: $break1) {
			height: 320px !important;
		}
	}
}

.chute-process__card {
	width: 100% !important;
	margin: 0;
	
	.img-wrapper {

		width: 100%;
		height: 300px !important;

		z-index: 1;

		@media(max-width: $break1) {
			height: 120px;
		}
	}
	
	figcaption {
		transform: translate(20px, -38px) !important;
	}
}

.chute-process__header {
	margin-bottom: 20px;
}

.contact-link {
    
	&:hover,
	&:active,
	&:focus {
		color: $brand-green;
	}
}