.services__header {
    &:after {
        content: '';
        display: block;
        width: 30px;
        margin-top: 30px;
        height: 3px;
        margin-right: auto;
        margin-left: auto;
        background-color: #7bba45;
    }
}

.header__link-block {
    li {
        list-style: none;
    }
}

.c-page-on-scroll .c-layout-header.c-layout-header-6 {
    background: #f5f5f5;
    border-bottom: 0;
    box-shadow: none;

  }

  .c-page-on-scroll .c-link {
    color: #444 !important;

  }

  .about-bgimage {
    background-image: image-url('cityscape.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

#feature-16-2 {
    background-image: image-url('building.jpg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#about .col-md-6.c-bg-dark {
    background-color: rgba(10, 18, 29, 0.9) !important;
}

#about-section {
    .c-content-box.c-size-md.c-no-padding.c-bg-img-center {
        background-image: image-url('bg-67.jpg');
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
}

.rich-text, .rich-text__list {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.rich-text h5 {
    font-weight: 700;
    font-size: 18px;
}

.footer {
    margin-top: 0px;
}

.cbp-caption-defaultWrap {
    background: #fff;
    img {
        height: 200px;
        width: auto;
        margin: 0 auto;
    }
}

.cbp-popup-lightbox-figure {
    background: #fff;
}

.contact__icon {
    &:hover, &:active {
        color: #7bba45;
    }
}

.c-font-white.c-font-60.c-theme-bg.c-float-left.homepage-insect-id {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    text-align: center;
    padding: 30px;
}

.link-green-hover {
    &:hover,
    &:active,
    &:focus {
        color: #7bba45;
    }
}


.margin-center {
    margin-right: auto;
    margin-left: auto;
}

.c-margin-top {
    margin-top: 40px;
}



ul.c-socials.c-theme-ul.footer-socials a {
    color: #fff;
    font-size: 28px;
    padding: 0 10px;
}


a.footer-logo {
    padding: 30px 20px 0 0;
    display: inline-block;
    vertical-align: middle;
}