
body {
  font-family: 'Roboto Condensed', sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
}

a {
  text-decoration: underline;
}

blockquote {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 18px;
  line-height: 22px;
}

.topbar__nav {
  display: block;
  text-align: center;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}

.topbar__container {
  height: 50px;
}

.topbar__link {
  padding: 0px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #0a121d;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.topbar__link:hover {
  color: #7bba45;
}

.topbar__link.w--current {
  color: #7bba45;
}

.topbar {
  display: block;
  position: relative;
  background-color: #f6f6f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .06);

}

.topbar__nav-link {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 20px;
  transition: color 0.3s;

  &:hover {
    color: #7bba45;
  }
}

.container.hero__container {
  display: block;
  height: 100%;
  padding-top: 50px;

  flex-direction: column;
 
  justify-content: center;

  text-align: left;
}

.hero-content {
  position: absolute;
  top: 40px;
  left: 40px;
}

.container.support__container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.container.services__container {
  padding-top: 40px;
  padding-bottom: 20px;
}

.container.banner__container {
  padding-top: 45px;
  padding-bottom: 45px;
}

.container.bed-bugs__container {
  padding-top: 40px;
}

.container.wildlife-container {
  margin-top: 40px;
}

.service__para--max-width {
  max-width: 800px;
  margin: 30px auto !important;
}

.services__header {
  margin-top: 0px;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  text-align: center;
}

.services__header.services__header--white {
  color: #fff;
}

.header__brand {
  margin-right: 10px;
  padding-right: 15px;
  border-right: 1px solid rgba(47, 53, 59, .25);
}

.header__link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 23px 1px 23px 33px;
  float: right;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__link {
  display: block;
  padding: 2px 10px 2px 0px;
  font-family: 'Roboto Condensed', sans-serif;
  color: hsla(0, 0%, 99%, .5);
  font-weight: 300;
  text-decoration: none;
  text-transform: none;
}

.footer__link:hover {
  color: #7bba45;
}

.footer__link.header__link--50 {
  opacity: 0.5;
}

.footer__link.header__link--50:hover {
  opacity: 1;
}

.footer__link.header__link--border-left {
  border-left: 1px solid rgba(47, 53, 59, .25);
}

.footer__link.footer__link--inline {
  display: inline-block;
  padding-left: 10px;
}

.header__button {
  padding: 7px 22px;
  background-color: #7bba45;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.header__button:hover {
  background-color: #5d972b;
}

.topbar__icon {
  display: inline-block;
  margin-left: 5px;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
}

.header__social {
  padding: 10px 12px;
  font-family: 'Font awesome 5 brands 400', sans-serif;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.header__social:hover {
  color: #7bba45;
}

.header__social.header__link--50 {
  opacity: 0.5;
}

.header__social.header__link--50:hover {
  opacity: 1;
}

.header__social.header__link--border-left {
  border-left: 1px solid rgba(47, 53, 59, .25);
}

.header__brand-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__brand-wrapper.w--current {
  display: block;
}

.slider {
  height: 500px;
  background-color: #fff;
}

.slider-slide-01 {
  background-color: #fff;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/cityscape-02.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/cityscape-02.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.slider-slide-02 {
  background-color: #fff;
  background-image: image-url('cityscape.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.topbar__link-fa {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.topbar__link-fa:hover {
  color: #7bba45;
}

.hero__heading {
  margin-top: 0px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 34px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.hero__sub-heading {
  margin-top: 0px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #647784;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
}


.hero__text {
  display: block;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.hero__button {
  margin-top: 20px;
  padding: 7px 22px;
  border: 2px solid #7bba45;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #7bba45;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.hero__button:hover {
  background-color: #7bba45;
  color: #fff;
}

.hero__button--white {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 40px;
  display: block;
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #fff;
  border-radius: 50px;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.hero__button--white:hover {
  background-color: #fff;
  color: #7bba45;
}

.support {
  background-color: #30353a;
}

.support__header {
  margin-top: 10px;
  color: #fff;
  text-transform: uppercase;
}

.support__parag {
  margin-top: 20px;
  color: hsla(0, 0%, 100%, .35);
  font-size: 18px;
  line-height: 22px;
}

.support__button {
  padding: 7px 22px;
  border: 2px solid #7bba45;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #7bba45;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.support__button:hover {
  background-color: #7bba45;
  color: #fff;
}

.services {
  background-color: #f6f6f6;
}

.topbar__dropdown {
  padding-right: 20px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #fff;
}

.topbar__dropdown:hover {
  color: #7bba45;
}

.topbar__dropdown.w--open {
  padding-right: 0px;
  background-color: #f6f6f6;
}

.dropdown-link {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: hsla(0, 0%, 100%, .35);
  font-weight: 400;
}

.dropdown-link:hover {
  color: #fff;
}

.dropdown-list {
  background-color: #2f353b;
}

.dropdown-list.w--open {
  width: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f6f6f6;
}

.divider {
  display: block;
  width: 30px;
  height: 4px;
  margin-right: auto;
  margin-left: auto;
  background-color: #7bba45;
}

.services__icon {
  display: block;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: #7bba45;
  font-size: 54px;
  line-height: 54px;
  text-align: center;
}

.services__col-header {
  text-align: center;
  margin-top: 20px;
}

.services__row {
  margin-top: 30px;
  margin-bottom: 20px;
}

.services__col-wrapper {
  height: 300px;
  padding: 35px 40px;
  background-color: #fff;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .08);
}

.services__button {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 30px;
  display: block;
  width: 140px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 7px 22px;
  background-color: #7bba45;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.services__button:hover {
  background-color: #5d972b;
}

.section__header {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1;
  height: 80px;
  background-color: #0a121d;
}


.dropdown-list--mega {
  background-color: #2f353b;
}

.dropdown-list--mega.w--open {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.header__brand--mobile {
  margin-right: 10px;
  padding-right: 15px;
  border-right: 1px solid rgba(47, 53, 59, .25);
}

.topbar__dropdown-link {
  display: inline-block;
  width: 100%;
  padding: 15px 25px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #5a5d5a;
  font-weight: 700;
  text-transform: uppercase;
}

.topbar__dropdown-link:hover {
  color: #7bba45;
}

.footer__heading {
  margin-bottom: 5px;
  color: #fff;
  text-transform: uppercase;
}

.header__link {
  display: block;
  padding: 2px 10px;
  -webkit-transition: opacity 300ms ease, color 300ms ease;
  transition: opacity 300ms ease, color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.header__link:hover {
  color: #7bba45;
}

.header__link.header__link--50 {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: hsla(0, 0%, 100%, .4);
}

.header__link.header__link--50:hover {
  color: #7bba45;
}

.header__link.header__link--50.w--current {
  color: hsla(0, 0%, 100%, .4);
}

.header__link.header__link--50.w--current:hover {
  color: #7bba45;
}

.header__link.header__link--border-left {
  border-color: hsla(0, 0%, 100%, .4);
  border-left-style: solid;
  border-left-width: 1px;
}

.topbar--fixed {
  position: fixed;
  width: 100%;
  background-color: #2f353b;
}

.header-control {
  position: absolute;
  height: 1px;
}

.banner__pest {
  margin-top: 130px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/pigeons.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/pigeons.jpg');
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.banner__header {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.section-header {
  margin-bottom: 30px;
}

.info-slider {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.info-slider__description {
  height: 300px;
  padding: 20px;
  background-color: #2f353b;
}

.info-slider__heading {
  color: #fff;
}

.info-slider__slide-01 {
  padding: 20px;
  background-image: url('../images/white-office.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-2 {
  height: 300px;
}

.tag {
  display: inline-block;
  padding: 3px 10px;
  background-color: #7bba45;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.banner__para {
  margin-bottom: 0px;
  color: #fff;
  font-weight: 300;
}

.ipm-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pest__button {
  position: relative;
  margin-top: 15px;
  padding: 7px 22px;
  float: right;
  background-color: #2f353b;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.pest__button:hover {
  background-color: #43668a;
}

.hero {
  position: relative;
  height: calc(100vh - 166px);
  padding-top: 0px;

  background-image: linear-gradient(180deg, rgba(#0D131C, 1), rgba(#0D131C, 0), rgba(#0D131C, 0), rgba(#0D131C, 0)), linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), image-url('freedom-tower-night.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.pest-list {
  margin-top: 20px;
}

.pest-list__card {
  background-image: -webkit-linear-gradient(270deg, #fff, #fff);
  background-image: linear-gradient(180deg, #fff, #fff);
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .08);
}

.lightbox-link {
  display: block;
}

.pest-list__card-img-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 200px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.pest-list__card-img {
  display: block;
  width: 100%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.pest-list__card-img.pest-list__card-img--height {
  width: auto;
  height: 100%;
}

.pest-list__header {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  padding: 2px 14px;
  background-image: -webkit-linear-gradient(270deg, #7bba45, #7bba45);
  background-image: linear-gradient(180deg, #7bba45, #7bba45);
  color: #fff;
  text-align: center;
}

footer > .container {
  padding-left: 25px;
  padding-right: 25px;
}

.footer__logo {
  margin-top: 10px;
  margin-left: -2px;
}

.footer__text {
  margin-top: 10px;
  margin-bottom: 10px;
  color: hsla(0, 0%, 99%, .5);
  line-height: 18px;
}

.footer__icon {
  display: inline-block;
  font-family: 'Font awesome 5 pro solid 900', sans-serif;
  color: hsla(0, 0%, 100%, .5);
  font-size: 14px;
  text-align: left;
}

.footer__colophon {
  height: 50px;
  margin-top: 20px;
  background-color: #000;
}

.info-slider__slide-02 {
  padding: 20px;
  background-image: url('../images/cityscape.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__colophon-text {
  margin-top: 15px;
  margin-bottom: 15px;
  color: hsla(0, 0%, 99%, .5);
  line-height: 18px;
  text-align: center;
}

.header__brand-link {
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.header__brand-link:hover {
  opacity: 0.51;
}

.header__brand-link.w--current:hover {
  opacity: 0.8;
}

.pest__paragraph {
  font-size: 16px;
}

.divide--white {
  display: block;
  width: 30px;
  height: 4px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}

.service__para {
  text-align: center;
}

.scroll-top {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  background-image: -webkit-linear-gradient(270deg, #2f353b, #2f353b);
  background-image: linear-gradient(180deg, #2f353b, #2f353b);
  font-family: 'Font awesome 5 pro 300', sans-serif;
  font-size: 16px;
}

.scroll-top-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 5;
  display: block;
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #7bba45;
  border-radius: 50px;
  background-color: #7bba45;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .25);
  -webkit-transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;
  font-family: 'Font awesome 5 pro 300', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.scroll-top-btn:hover {
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .2);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.page-top {
  position: relative;
}

.paragraph {
  color: hsla(0, 0%, 100%, .7);
}

.rich-text {
  color: #444;
  font-size: 16px;
  text-transform: none;
}

.rich-text h4 {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 5px 10px;
  background-color: #7bba45;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.rich-text h5 {
  margin-top: 25px;
  margin-bottom: 5px;
}

.body {
  background-color: #f6f6f6;
}

.rich-text__list {
  color: #5a5d5a;
  font-weight: 700;
}

.rich-text__list--lvl-2 {
  margin-left: 15px;
  list-style-type: square;
}

.topbar__linkblock {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #0a121d;
  text-decoration: none;
}

.topbar__linkblock:hover {
  color: #7bba45;
}

.topbar__linkblock.w--current {
  display: inline-block;
}

.topbar__link--dropdown {
  padding: 0px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #0a121d;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.topbar__link--dropdown:hover {
  color: #7bba45;
}

.topbar__link--dropdown.w--current {
  color: #7bba45;
}

.quote-btn {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 5;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #7bba45;
  border-radius: 50px;
  background-color: #7bba45;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .25);
  -webkit-transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease;
  transition: box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.quote-btn:hover {
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .2);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.banner__chute {
  margin-top: 130px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/kitchen.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/kitchen.jpg');
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.banner__air-duct {
  margin-top: 130px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/building.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/building.jpg');
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.about__header {
  margin-top: 10px;
  color: #5a5d5a;
  text-transform: uppercase;
}

.about__parag {
  margin-top: 20px;
  color: #5a5d5a;
  font-size: 18px;
  line-height: 22px;
}

.support__col-2 {
  padding-top: 45px;
}

.ny-svg {
  width: 350px;
}

.ny-svg__wrapper {
  text-align: center;
}

.banner__contact {
  margin-top: 130px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/phone.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/phone.jpg');
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.quote {
  background-color: #f6f6f6;
}

.quote__text-wrapper {
  margin-bottom: 20px;
  padding: 8px 15px 15px;
  background-color: #fff;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .08);
}

.quote__heading {
  margin-top: 0px;
  color: #5a5d5a;
}

.quote__text {
  display: block;
}

.quote__link {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #2f353b;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.quote__link:hover {
  color: #5a5d5a;
}

.quote__form-wrapper {
  margin-bottom: 20px;
  padding: 5px 15px 15px;
  background-color: #fff;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .08);
}

@media (max-width: 991px) {
  .topbar__nav {
    width: 300px;
    padding: 20px;
    background-color: #2f353b;
  }
  .topbar__container {
    height: 60px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .topbar__link {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #f6f6f6;
  }
  .topbar {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 60px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #fff;
  }
  .container.hero__container {
    padding-top: 200px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .container.support__container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .topbar__icon {
    margin-left: 0px;
    font-family: 'Font awesome 5 pro 400', sans-serif;
  }
  .slider {
    margin-top: 0px;
  }
  .topbar__dropdown {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .topbar__dropdown.w--open {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: transparent;
  }
  .dropdown-link {
    padding-left: 40px;
  }

  .services__col-wrapper {
    height: 340px;
  }
  .services__button {
    position: relative;
    bottom: 0px;
  }
  .text-block {
    font-family: 'Font awesome 5 pro solid 900', sans-serif;
  }
  .section__header {
    display: none;
  }
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  .topbar__menu-btn {
    padding: 20px;
  }
  .topbar__menu-btn:active {
    color: #7bba45;
  }
  .topbar__menu-btn.w--open {
    background-color: transparent;
    color: #7bba45;
  }
  .header__brand--mobile {
    margin-top: 3px;
    margin-right: 0px;
    padding-right: 0px;
    float: left;
    border-right-style: none;
  }
  .topbar__dropdown-link {
    display: inline-block;
    padding-right: 0px;
    padding-bottom: 15px;
    color: #5a5d5a;
  }
  .topbar__dropdown-link.w--current {
    color: #5a5d5a;
  }
  .topbar__dropdown-icon {
    display: inline-block;
    float: right;
    font-family: 'Font awesome 5 pro solid 900', sans-serif;
  }
  .topbar--fixed {
    padding-right: 20px;
    padding-left: 20px;
    background-color: #fff;
  }
  .banner__pest {
    margin-top: 60px;
  }
  .info-slider__description {
    overflow: hidden;
  }
  .pest__button {
    position: relative;
    bottom: 0px;
  }
  .pest-list__card-img-wrapper {
    width: 100%;
  }
  .header__brand-link--mobile {
    display: inline-block;
    padding: 10px;
    float: left;
  }
  .header__brand-link--mobile.w--current {
    padding: 12px 0px;
  }
  .topbar__linkblock {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    color: #f6f6f6;
  }
  .topbar__link--dropdown {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #f6f6f6;
  }
  .dropdown__text {
    display: inline-block;
  }
  .banner__chute {
    margin-top: 60px;
  }
  .banner__air-duct {
    margin-top: 60px;
  }
  .banner__contact {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .topbar {
    padding-right: 10px;
    padding-left: 10px;
  }
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .container.hero__container {
    padding-top: 100px;
    padding-right: 45px;
    padding-left: 45px;
  }
  .container.support__container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .container.services__container {
    padding-bottom: 0px;
  }
  .container.banner__container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer__link {
    padding-right: 0px;
    text-transform: none;
  }
  .slider {
    height: 400px;
  }
  .services__col-wrapper {
    height: auto;
    margin-bottom: 10px;
  }
  
  .footer__heading {
    margin-top: 20px;
  }
  .header__link {
    padding-right: 0px;
    text-transform: none;
  }
  .topbar--fixed {
    padding-right: 10px;
    padding-left: 10px;
  }
  .info-slider__description {
    height: auto;
  }
  .ipm-section {
    padding-right: 10px;
    padding-left: 10px;
  }
  .hero {
    padding-top: 65px;
  }
  .pest-list {
    margin-top: 0px;
  }
  .pest-list__col {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .pest-list__card {
    height: 100px;
  }
  .pest-list__card-img-wrapper {
    width: 200px;
    height: 100%;
  }
  .pest-list__card-img.pest-list__card-img--height {
    width: auto;
    height: 200px;
  }
}

@media (max-width: 479px) {
  .topbar__nav {
    width: 100%;
    margin-top: 60px;
  }
  .topbar {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container.hero__container {
    margin-top: 0px;
    padding: 80px 20px 0px;
  }

  .hero__heading {
    font-size: 34px;
    line-height: 36px;
  }
  .services__icon {
    text-align: center;
  }
  .services__col-header {
    margin-top: 25px;
  }
  .services__col-wrapper {
    margin-bottom: 10px;
  }
  .services__button {
    display: block;
    width: 150px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .header__brand--mobile {
    max-width: 80%;
    margin-top: 1px;
  }
  .topbar__dropdown-link.w--current {
    color: #7bba45;
  }
  .topbar--fixed {
    padding-right: 0px;
    padding-left: 0px;
  }
  .pest__button {
    display: block;
    width: 120px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .hero {
    height: calc(100vh - 110px);
  }
  .footer__icon {
    text-align: center;
  }
  .header__brand-link--mobile.w--current {
    padding: 16px 15px;
  }
}

