
// Colors
//------------------------

$brand-green: #89B756;
$text-gray: #555;
$interior-section-gray: #FBFBFC;


// Breakpoints
//------------------------

$break0: 1200px;
$article-width: 960px;
$break1: 768px;
$break2: 480px
