
#services__container {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s ease;

    &.anime {
        opacity: 1;
        transform: translateY(0px);
    }
}